
import { defineComponent, onBeforeMount, ref } from 'vue'
import { LogConfig } from '@/types'
import { getUserLogs } from '@/api/user'
import { ERR_OK } from '@/api/config'
export default defineComponent({
  name: 'UserLog',
  setup () {
    const logsList = ref<LogConfig[]>([])
    onBeforeMount(async () => {
      const { code, data } = await getUserLogs<LogConfig>()
      if (code === ERR_OK) {
        logsList.value = data.list
      }
    })
    return { logsList }
  }
})
